<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.users')"
                :pgIcon="'bx bx-user'"
                :refs="refs"
                :pgCurrent="pgCurrent"
                :addNew="false">
            </Breadcrumb>

            <div class="row">

                <Loading
                    v-if="pgLoading">
                </Loading>

                <div v-if="!pgLoading" class="col-8">

                    <FormSingleLang
                        :label="$t('view.user_info')"
                        :labelName="$t('view.username')"
                        :labelEmail="$t('view.email')"
                        :labelPassword="$t('view.password')"
                        :labelCountryCode="$t('view.ccode')"
                        :labelMobile="$t('view.mobile')"
                        
                        :hasName=true
                        :name="row.name"

                        :hasEmail=true
                        :email="row.email"

                        :hasPassword=true
                        :password="row.password"

                        :hasCountryCode=true
                        :country_code="row.country_code"

                        :hasMobile=true
                        :mobile="row.mobile"

                        v-on:formChange="handleFormChange">
                    </FormSingleLang>

                </div>

                <div v-if="!pgLoading" class="col-4">

                    <CardImage
                        :labelImage="$t('view.image')"
                        :image_base64="row.image_baes64"
                        :image_preview="row.image_preview"

                        v-on:imageChange="handleImageChange">
                    </CardImage>

                    <CardRole
                        v-if="auth.role == 'root'"
                        :role="row.role"
                        v-on:roleChange="handleRoleChange">
                    </CardRole>

                    <CardStatus
                        v-if="auth.role == 'root'"
                        :status="row.status"

                        v-on:statusChange="handleStatusChange">
                    </CardStatus>
                
                </div>
            </div>


            <Buttons
                v-if="!pgLoading"
                :btnLoading="btnLoading"
                :btnCurrent="btnCurrent"
                        
                v-on:submitClicked="handelSubmit"
                v-on:cancelClicked="handleCancel">
            </Buttons>

        </div>
    </div>

</template>

<script>
import iziToast from 'izitoast';

export default {
    name: 'EditOrNew',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        Loading: () => import('@/components/Loading.vue'),
        FormSingleLang: () => import('@/components/FormSingleLang.vue'),
        CardImage: () => import('@/components/CardImage.vue'),
        CardRole: () => import('@/components/CardRole.vue'),
        CardStatus: () => import('@/components/CardStatus.vue'),
        Buttons: () => import('@/components/Buttons.vue')
    },
    data(){
        return {    
            // auth
            auth: {
                role: '',
                access_token: '',
            },

            // row
            row: {
                name: '',
                email: '',
                password: '',
                country_code: '',
                mobile: '',

                role: '',

                image_base64: '',
                image_preview: '',

                status: 1,
            },
                
            msgCurrent: (this.$route.params.id) ? 'Updated' : 'Created',
            btnCurrent: (this.$route.params.id) ? 'Update' : 'Create',
            methodType: (this.$route.params.id) ? 'PUT' : 'POST',
            pgLoading: (this.$route.params.id) ? true : false,
            pgCurrent: (this.$route.params.id) ? 'Edit' : 'Add New',
            btnLoading: false,
            
            refs: 'users',
        }
    },
    mounted() {},
    created() {
        // AccessToken & Role
        if(localStorage.getItem('access_token')) {
            this.auth.access_token = localStorage.getItem('access_token');
        }
        if(localStorage.getItem('role')) {
            this.auth.role = localStorage.getItem('role');
        }

        if(this.$route.params.id) {
            this.fetchRow();
        }
    },
    methods: {
        
        // fetch Row
        fetchRow() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/'+this.refs+'/'+this.$route.params.id,
                method: 'GET',
                data: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;

                this.row.name = res.data.row.name;
                this.row.email = res.data.row.email;

                this.row.country_code = res.data.row.country_code;
                this.row.mobile = res.data.row.mobile;

                this.row.image_preview = (res.data.row.image) ? res.data.row.image.url : '';
                this.row.image_base64 = (res.data.row.image) ? res.data.row.image.url : '';

                this.row.role = res.data.row.role;
                this.row.status = res.data.row.status;
            })
            .catch(err => {

                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }

            })
            .finally(() => {});
        },
            

        // handle edit Or New submit
        handelSubmit(){
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
            const options = {
                url: (this.$route.params.id) ? window.baseURL+'/'+this.refs+'/'+this.$route.params.id : window.baseURL+'/'+this.refs,
                method: this.methodType,
                data: {
                    name: this.row.name,
                    email: this.row.email,
                    password: this.row.password,
                    country_code: this.row.country_code,
                    mobile: this.row.mobile,
                    image_base64: this.row.image_base64,
                    status: this.row.status,
                    role: this.row.role,
                }
            }
            this.axios(options, config)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'bx bx-wink-smile',
                    title: '',
                    message: (this.btnCurrent == 'Update') ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح'
                });
                    
                this.$router.push({ name: this.refs });
            })
            .catch(err => {
                        
                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
            })
            .finally(() => {})

        },

          
        // clear Local Storage
        clearLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('avatar');
            localStorage.removeItem('username');
            localStorage.removeItem('encrypt_id');
            localStorage.removeItem('role');
        },


        // Cancel
        handleCancel(){
            if(confirm('هل انت متأكد؟')) {
                this.$router.push({ name: this.refs });
            }
        },


        // handel Changes from child components
        handleFormChange(event) {
            this.row.name = event.name;
            this.row.email = event.email;
            this.row.password = event.password;
            this.row.country_code = event.country_code;
            this.row.mobile = event.mobile;
        },

        handleImageChange(event) {
            this.row.image_base64 = event.image_base64;
        },

        handleRoleChange(event) {
            this.row.role = event.role;
        },

        handleStatusChange(event) {
            this.row.status = event.status;
        },

    },
}
</script>
